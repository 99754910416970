<template>
    <painel titulo="Complexidade" :refreshFunction="obterTodos" v-if="mostrarListagem">
        <div class="table-responsive my-5">
            <listagem-sol
                :idTabela="idTabela"
                :campos="campos"
                :data="data"
                :inativos="inativos"
                @onInativar="inativar"
                @onEditar="editar"
                @onAtivar="ativar"
                @onInserir="inserir"
                @mostrarInativos="mostrarInativos"
            ></listagem-sol>
        </div>
    </painel>
    <router-view></router-view>
</template>

<script>
import ListagemSol from '../components/ListagemSol.vue';
import Services from './service';

export default {
    name: 'Complexidade',
    components: {
        ListagemSol,
    },
    data() {
        return {
            idTabela: 'Complexidade',
            inativos: true,
            data: [],
            campos: [
                { campo: 'title', titulo: 'Título' },
                { campoModified: 'modified', titulo: 'Modificado' },
                { campo: 'modifiedBy', titulo: 'Modificado por' },
            ],
        };
    },
    mounted() {
        this.obterTodos();
    },
    computed: {
        mostrarListagem() {
            return this.$route.name === 'Complexidade';
        },
    },
    beforeRouteUpdate() {
        this.obterTodos();
    },
    methods: {
        obterTodos() {
            this.$store.dispatch('addRequest');
            Services.obterTodos(this.inativos ? null : true).then((response) => {
                if (response?.success) {
                    this.data = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        mostrarInativos(inativo) {
            this.inativos = inativo;
        },
        inserir() {
            this.$router.push({
                name: `Complexidade_Inserir`,
            });
        },
        inativar(objeto) {
            this.$store.dispatch('addRequest');
            Services.excluir(objeto.id).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'warn',
                        summary: 'Inativação',
                        detail: 'Complexidade inativada com sucesso',
                        life: 3000,
                    });
                    this.obterTodos();
                }
                this.$store.dispatch('removeRequest');
            });
        },
        ativar(objeto) {
            this.$store.dispatch('addRequest');
            Services.atualizar(objeto.id, objeto).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Ativação',
                        detail: 'Complexidade ativada com sucesso',
                        life: 3000,
                    });
                    this.obterTodos();
                }

                this.$store.dispatch('removeRequest');
            });
        },
        editar(complexidade) {
            this.$router.push({
                name: `Complexidade_Atualizar`,
                params: {
                    id: complexidade.id,
                },
            });
        },
    },
    watch: {
        inativos() {
            this.obterTodos();
        },
    },
};
</script>

<style>
</style>